@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

$md-primary: (
    50 : #eef2f9,
    100 : #dbe4f2,
    200 : #c8d6eb,
    300 : #b5c8e5,
    400 : #a3bade,
    500 : #90ACD7,
    600 : #7d9ed0,
    700 : #6b90c9,
    800 : #5882c3,
    900 : #4574bc,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #3d68aa,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #f5f8fc,
    100 : #e6eef9,
    200 : #d6e2f5,
    300 : #c5d6f0,
    400 : #b8ceed,
    500 : #acc5ea,
    600 : #a5bfe7,
    700 : #9bb8e4,
    800 : #92b0e1,
    900 : #82a3db,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #e5efff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (
    50 : #f9e0e0,
    100 : #f0b3b3,
    200 : #e68080,
    300 : #db4d4d,
    400 : #d42626,
    500 : #cc0000,
    600 : #c70000,
    700 : #c00000,
    800 : #b90000,
    900 : #ad0000,
    A100 : #ffd7d7,
    A200 : #ffa4a4,
    A400 : #ff7171,
    A700 : #ff5858,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$test-app-primary: mat.define-palette($md-primary, 500, 500, 500);
$test-app-accent: mat.define-palette($md-secondary, 900, 700, A500);
$test-app-warn: mat.define-palette($md-warn, 900, A400, A500);

$test-app-theme: mat.define-light-theme($test-app-primary, $test-app-accent, $test-app-warn);

@include mat.all-component-themes($test-app-theme);


.validation-div {
    color: mat.get-color-from-palette($test-app-warn);
}


.ddp-activity-validation {
    @extend .ddp-activity-validation;
    width: 100%;
    margin: 0;
}