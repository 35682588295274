@use '@angular/material' as mat;
@import 'app-theme.scss';
@import './styles/activity.scss';
@import "app/components/end-enroll/end-enroll.component.css";

$warning-height: 150px;

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 16px;
    padding-right: 0 !important;
    background-color: mat.get-color-from-palette($app-theme, 200);
}

body {
    font-family: 'Roboto', sans-serif;
}

@media only screen and (min-width: 1380px) {
    html {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1280px) {
    html {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) {
    html {
        font-size: 14px;
    }
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.2rem;
}

h3 {
    font-size: 1.1rem;
}

p {
    font-size: 1rem;
}

a {
    text-decoration: none !important;
    outline: 0;

    &:hover, &:active, &:focus {
        outline: 0;
        color: mat.get-color-from-palette($app-theme, 700);
    }
}

.row {
    display: block;

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

.intro-row {
    margin-top: 150px;
}

// App Header
.Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 90px;
    padding: 0 35px 5px 35px !important;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    background-color: mat.get-color-from-palette($app-theme, 50);

    &--scrolled {
        color: mat.get-color-from-palette($app-theme, 400);
        background-color: mat.get-color-from-palette($app-theme, 200);
        box-shadow: 0 1px 8px mat.get-color-from-palette($app-theme, 300);
    }
}

.HeaderInfo {
    max-width: 660px;
    display: inline-block;
    margin: 0 0 0 20px;

    &--scrolled {
        margin: 0;
    }

    a {
        color: mat.get-color-from-palette($app-theme, 600);
    }
}

.Header-navItemLink {
    font-size: 1rem;
    transition: color 0.3s ease;
    cursor: pointer;
    font-weight: 300;
}

.HeaderLink {
    transition: color 0.3s ease;
    color: mat.get-color-from-palette($app-theme, 100);

    &:hover {
        color: mat.get-color-from-palette($app-theme, 700);
    }
}

.PageHeader-activity-subtitle {
    font-size: 0.95rem;
    font-weight: 300;
    line-height: 1.6em;
    color: mat.get-color-from-palette($app-theme, 100);
    margin: 5px 0 5px 0;
}

.Header-logoImg {
    height: 68px;
}

.Header-logo {
    margin: 0 0 0 50px;
    cursor: pointer;
    height: 100%;
    align-items: baseline;
    display: flex;
    align-self: flex-start;

    &--small {
        margin: 0;
    }

    &--hide {
        display: none;
    }
}

.Header-link {
    display: flex;
    align-items: flex-start;
}

.Header-logoText {
    margin: 13px 0 0 10px;
    color: mat.get-color-from-palette($app-theme, 100);
    font-size: 18px;
    font-weight: 300;
    line-height: 19px;

    &--Scrolled {
        color: mat.get-color-from-palette($app-theme, 400);
    }
}

.Header-nav {
    margin: 0 50px 0 0;

    &--small {
       margin: 0;
    }
}

.Header-navList {
    display: flex;
    margin: 0;

    &--small {
        padding: 0;
    }
}

.Header-navItem {
    display: inline-flex;
    align-items: center;
    font-size: 0.95rem;
    line-height: 1.6em;
    text-decoration: none;
    padding: 0 0 0 20px;
    transition: background-color 0.3s ease;
}

.CountButton {
    font-weight: 700;
    border: 2px solid mat.get-color-from-palette($app-theme, 100);
    border-radius: 18px;
    padding: 5px 20px;
    color: mat.get-color-from-palette($app-theme, 100);
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    transition: all 0.3s ease;

    &:hover {
        color: mat.get-color-from-palette($app-theme, 700);
        border: 2px solid mat.get-color-from-palette($app-theme, 700);
        transition: all 0.3s ease;
    }

    &--Scrolled {
        background-color: mat.get-color-from-palette($app-theme, 2200);
        border: none;
        margin: 2px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        transition: box-shadow 0.3s ease;

        &:hover {
            color: mat.get-color-from-palette($app-theme, 100);
            border: none;
            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        }
    }
}

.MenuButton {
    display: inline-flex;
    text-decoration: none;
    color: mat.get-color-from-palette($app-theme, 100);
    cursor: pointer;
}

.MenuButton-Icon--Scrolled {
    color: mat.get-color-from-palette($app-theme, 600);
}

.SimpleButton {
    background: none;
    border: none;
    padding: 0;
    outline: none;
    font-size: 1rem;
    transition: color 0.3s ease;
    cursor: pointer;
    font-weight: 300;
    text-decoration: none;
    color: mat.get-color-from-palette($app-theme, 100);
    text-align: left;
    transition: all 0.3s ease;

    &--Scrolled {
       color: mat.get-color-from-palette($app-theme, 2200);
    }

    &:hover {
        color: mat.get-color-from-palette($app-theme, 700);
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
        color: mat.get-color-from-palette($app-theme, 600);
    }
}

.LastUpdatedText {
    text-align: right;
    font-weight: 300;
    padding: 5px;
    color: mat.get-color-from-palette($app-theme, 800);
}

// Footer
.Footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 185px;
    /* Height of the footer */
    padding: 0 35px 0 35px;
    z-index: 2;
    background-color: mat.get-color-from-palette($app-theme, 200);
    display: flex;
    flex-direction: column;
}

.Footer-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Footer-logo {
    width: 150px;
    margin: 0 0 0 25px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
}

.Footer-logoCMI {
    padding: 15px 25px 0 25px;
}

.Footer-logoCMI-img {
    height: 52px;
    width: auto;
}

.Footer-logoImg {
    height: 68px;
}

.Footer-logoText {
    margin: 13px 0 0 10px;
    color: mat.get-color-from-palette($app-theme, 400);
    font-size: 18px;
    font-weight: 300;
    line-height: 19px;
}

.Footer-nav {
    display: flex;
    flex-direction: column;
    margin: 30px 50px 0 25px;
}

.Footer-navList {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;
}

.Footer-navItem {
    font-size: 0.95rem;
    line-height: 1.6em;
    text-decoration: none;
    padding-left: 20px;

    &--first {
        padding: 0;
    }
}

.Footer-navItem--space {
    padding-left: 250px;
    padding-right: 180px;
}

.Footer-navItem--logo {
    padding-left: 100px;
    padding-right: 180px;
}

.Footer-navItem--space-contact {
    padding-left: 250px;
    padding-right: 0px;
}

.Footer-navItemLink {
    transition: color 0.3s ease;
    cursor: pointer;
    color: mat.get-color-from-palette($app-theme, 500);
    font-weight: 300;

    &:hover {
        color: mat.get-color-from-palette($app-theme, 2200);
        text-decoration: none;
    }
}

.Footer-contact {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px 0 0 0;
}

.Footer-contactList {
    color: mat.get-color-from-palette($app-theme, 500);
    font-weight: 300;
    list-style-type: none;
    padding: 0;

    &--right {
        padding: 0 0 0 50px;
    }
}

.Footer-contactList-2 {
    color: mat.get-color-from-palette($app-theme, 500);
    font-weight: 300;
    list-style-type: none;
    padding: 0 0 0 100px;
}

.Footer-contactLink {
    transition: color 0.3s ease;
    cursor: pointer;
    color: mat.get-color-from-palette($app-theme, 500);
    font-weight: 300;

    &:hover {
      color: mat.get-color-from-palette($app-theme, 2200);
      text-decoration: none;
    }
}

.Footer-contactLogos {
    padding-right: 5px;
    height: 15px;
}

@media only screen and (max-width: 1200px) {
    .Footer-nav {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 30px 0 0 25px;
    }
}

//   Page header
.MainContainer {
    position: relative;
    min-height: 100%;
    background-color: mat.get-color-from-palette($app-theme, 200);
}

.PageHeader {
    position: relative;
    padding-top: 0;
    margin-bottom: 20px;
}

.PageHeader-background {
    height: auto;
    padding: 78px 0 5px 0;
    color: mat.get-color-from-palette($app-theme, 100);
    width: 100%;
    background-color: mat.get-color-from-palette($app-theme, 600);
    display: flex;
}

.PageLayout {
    max-width: 660px;
    width: 100%;
    margin: 0 auto;
}

@media only screen and (min-width: 1440px) {
    .PageLayout {
        max-width: 900px;
    }
}

.PageLayout-dashboard {
    max-width: 1200px !important;
}

.PageHeader-title {
    display: inline-block;
    font-weight: 300;
    font-size: 2.4rem;
    line-height: 2.7rem;

    small {
        color: mat.get-color-from-palette($app-theme, 100);
        font-style: italic;
        font-weight: 300;
        font-size: 55%;
        display: block;
        line-height: 1.5;
    }
}

.PageHeader-subTitle {
    white-space: normal;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6em;
    color: mat.get-color-from-palette($app-theme, 600);
}


.PageHeader-image {
    .PageHeader-imageSpan {
        display: inline-block;
        width: 100%;
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        background-size: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: url(/assets/images/about-page-broad-hero.jpg);
        position: relative;

        .PageHeader-imageInner {
            padding-top: 42%;
            display: block;
            height: 0;
        }
    }
}

.PageHeader-box {
    position: relative;
    bottom: 150px;
    width: 60%;
    margin: 0 auto !important;
    border-radius: 2px;
    background-color: mat.get-color-from-palette($app-theme, 100);
    text-align: left;
    z-index: 1;
}

.PageHeader-boxContent {
    color: mat.get-color-from-palette($app-theme, 1000);
    padding: 40px;
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 300;
}

.PageHeader-boxFooter {
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 20px 40px;
    font-weight: 300;
    font-size: 0.95rem;
}

.PageHeader-backgroundAboutUs {
    height: auto;
    padding: 120px 0 0 0;
    margin-top: -300px;
    color: mat.get-color-from-palette($app-theme, 100);
    width: 100%;
    background-color: mat.get-color-from-palette($app-theme, 600);
    z-index: 2;
}

.PageHeader-titleAboutUs {
    margin: 100px 0 30px 0;
    text-align: center;
    display: block;
}

.Message {
    text-align: center;
    color: mat.get-color-from-palette($app-theme, 1000);
    display: block;
}

.Message-title {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.25;
    color: mat.get-color-from-palette($app-theme, 1000);
    margin-bottom: 18px;
}

.Message-partners {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    height: 210px;
}

.partner-logo {
    max-width: 250px;
    width: 100%;

    &--single {
        max-width: 500px;
        width: 100%;
    }
}

.Wrapper {
    position: relative;
    min-height: 100%;
    margin: 0 35px;
    padding-bottom: 250px;
    background-color: mat.get-color-from-palette($app-theme, 100);
    z-index: 1;
}

.NoMargin {
    margin: 0 !important;
}

.NoPadding {
    padding: 0 !important;
}

.Float--right {
    float: right !important;
}

.Position--relative {
    position: relative;
}

.row--moreBottomMargin {
    margin-bottom: 120px;
}

@media only screen and (max-width: 450px) {
    .row--moreBottomMargin {
        margin-bottom: 180px;
    }
}

.row--moreTopMargin {
    margin-top: 40px;
}

@media only screen and (max-width: 1480px) {
    .Footer-logo {
        order: 1;
    }

    .Footer-logoCMI {
        order: 2;
    }

    .Footer-nav {
        order: 3;
        width: 100%;
    }
}

@media only screen and (max-width: 1025px) {
    .Wrapper {
        margin: 0;
        padding-bottom: 350px;
    }

    .Header-nav {
        margin: 0;
    }

    .Header-logo {
        margin: 0;
    }

    .Footer-logo {
        margin: 0;
    }

    .Footer-nav {
        margin: 30px 50px 0 0;
    }
}

@media only screen and (max-width: 478px) {

    .Footer-logoImg,
    .Header-logoImg {
        width: 19px;
        height: auto;
    }

    .Footer-logoText,
    .Header-logoText {
        left: 28px;
        font-size: 15px;
        line-height: 17px;
    }
}

@media only screen and (max-width: 370px) {
    .Header {
        min-height: 65px;
    }

    .Footer-logoImg,
    .Header-logoImg {
        width: 16px;
    }

    .Footer-logoText,
    .Header-logoText {
        font-size: 13px;
        line-height: 15px;
    }
}

@media only screen and (max-width: 770px) {
    .PageHeader-box {
        bottom: 0;
        width: 100%;
        margin: 0 auto !important;
    }

    .PageHeader-titleAboutUs {
        margin: 180px 0 30px 0 !important;
    }


    .PageHeader-backgroundAboutUs {
        margin-top: -270px;
    }
}

@media only screen and (max-width: 810px) {

    .Footer-navList {
        flex-direction: column;
    }

    .Footer-navItem {
        padding: 0;
    }
}

@media only screen and (max-width: 450px) {
    .PageContent-ol li,
    .PageContent-ul li {
        padding: 0 0 0 15px;
    }

    .PageHeader-background {
        padding: 70px 0 0 0;
        margin-top: 0;
    }

    .PageHeader-title {
        padding: 20px 20px 0 20px;
    }

    .PageContent {
        padding: 20px 0 150px 0;
    }

    .Message-title {
        font-size: 2rem;
    }

    .PageHeader-title {
        padding: 20px 20px 0 20px;
    }

    .PageHeader-activity-subtitle {
        padding: 0 20px 10px 20px;
    }

    .Modal-title {
        margin: 0 !important;
        font-size: 2rem !important;
        line-height: 2rem !important;
    }

    .Modal-text {
        margin: 0 !important;
    }

    .modal-message {
        .warning-message-text {
            margin: 0 !important;
        }
    }

    .mat-dialog-actions {
        padding: 12px 0 0 0 !important;

        .ButtonFilled {
            padding: 9px 10px !important;
        }
    }

    .Footer-logoCMI-img {
        width: auto;
        height: 40px;
    }

    .Header,
    .Footer {
        padding: 0 15px !important;
    }

    .PageHeader-subTitle {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .Header-navItem {
       padding-left: 10px !important;
    }

    .CountButton {
        padding: 5px 12px !important;
    }
}

// Page
.PageContent {
    padding: 50px 0 150px 0;
}

.PageContent-section {
    padding: 1rem 0;
}

.PageContent-section.council {
    padding: 1rem;
}

.Dashboard-info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 0 20px 0;
    padding: 35px 35px 30px 35px;
    background: mat.get-color-from-palette($app-theme, 200);
    border: 2px solid mat.get-color-from-palette($app-theme, 600);
    position: relative;

    .close-button {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
    }
}

ul {
    li.PageContent-text-list {
        padding: 0 !important;
        margin: 10px 0 !important;
    }
}

.PageContent-text,
.PageContent-box-text {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: mat.get-color-from-palette($app-theme, 1000);
    text-align: left;
}

.PageContent-image {
    width: 100%;
    height: auto;
}

.Link {
    color: mat.get-color-from-palette($app-theme, 2200) !important;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover, &:focus {
      color: mat.get-color-from-palette($app-theme, 700) !important;
    }
}

.PageContent-title {
    font-size: 1.4rem;
    color: mat.get-color-from-palette($app-theme, 1000);
    font-weight: 300;
    line-height: 1.33;
    margin: 2.1rem 0 1.3rem 0;
}

.PageContent-title.council {
    font-size: 2.2rem;
    text-align: center;
    margin: 2.5rem 0;
}

.PageContent-sectionAboutUs {
    padding: 0;
}

.HorizontalLine {
    margin: 40px 0;
}

.topMarginMedium {
    margin: 15px 0 0 0;
}

.Italic {
    font-style: italic;
}

.Color--neutral {
    color: mat.get-color-from-palette($app-theme, 800) !important;
}

.Normal {
    font-weight: 300 !important;
}

.ButtonFilled {
    outline: none;
    padding: 7px 20px;
    border-radius: 30px !important;
    border: none;
    color: mat.get-color-from-palette($app-theme, 100) !important;
    font-size: 1rem;
    font-weight: 300;
    transition: all 0.3s ease-out;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    background-color: mat.get-color-from-palette($app-theme, 2200) !important;

    &:focus {
        outline: none;
    }

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
        color: mat.get-color-from-palette($app-theme, 100);
    }

    &:visited {
        color: mat.get-color-from-palette($app-theme, 100);
    }

    &:disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
    }

    &--white {
        background-color: mat.get-color-from-palette($app-theme, 100) !important;
        color: mat.get-color-from-palette($app-theme, 1500) !important;
        font-size: 0.95rem;
        margin-top: 20px;

        &:hover {
           background-color: mat.get-color-from-palette($app-theme, 100) !important;
        }
    }

    &--neutral {
        background-color: mat.get-color-from-palette($app-theme, 300) !important;
        margin-right: 0;

        &--margin {
            margin-right: 20px;
        }
    }
}

.Button--rect {
    float: right;
    border-radius: 2px !important;
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.95rem;
    font-weight: 300;
    vertical-align: middle;
    z-index: 1;
    transition: all 0.3s ease-out;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.Button--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.ButtonFloating:hover,
.Button--rect:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
}

// welcome
.Intro {
    position: absolute;
    top: 0;
    margin: 0;
    max-width: 100%;
    display: inline-block;
}

.Intro-image {
    .Intro-imageSpan {
        display: inline-block;
        width: 100%;
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        background-size: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-image: url(/assets/images/home-image-lg.jpg);
        position: relative;

        .Intro-imageInner {
            padding-top: 50%;
            display: block;
            height: 0;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .Intro-image {
        .Intro-imageSpan {
            background-image: url(/assets/images/home-image-lg.jpg);

            .Intro-imageInner {
                padding-top: 51% !important;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .Intro-image {
        .Intro-imageSpan {
            background-image: url(/assets/images/home-image-md.jpg);

            .Intro-imageInner {
                padding-top: 70% !important;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .Intro-image {
        .Intro-imageSpan {
            background-image: url(/assets/images/home-image-sm.jpg);

            .Intro-imageInner {
                padding-top: 68% !important;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .Intro-image {
        .Intro-imageSpan {
            background-image: url(/assets/images/home-image-xs.jpg);

            .Intro-imageInner {
                padding-top: 175% !important;
            }
        }
    }
}

.Intro-arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -32px;
    opacity: 1;
    z-index: 5;
    cursor: pointer;
}

.Intro-footer {
    text-align: center;
    margin: 0 0 -50px 0;
}

.Intro-footerLogos {
    margin: 10px 20px;
    height: 30px;
}

@media only screen and (max-width: 450px) {
    .Intro-footerLogos {
        margin: 10px 10px;
    }
}

.Message--intro {
    margin-top: 150px;

    .Message-title,
    .Message-text {
        color: mat.get-color-from-palette($app-theme, 1000);
    }
}

@media only screen and (max-width: 1500px) {
    .Message--intro {
        margin-top: 65px;
    }
}

@media only screen and (max-width: 1280px) {
    .Message--intro {
        margin-top: 150px;
    }
}

@media only screen and (max-width: 1024px) {
    .Message--intro {
        margin-top: 100px;
    }

    .Footer-logoCMI {
        padding-right: 0 !important;
    }
}

@media only screen and (max-width: 450px) {
    .Message--intro {
        margin-top: 100px;
    }
}

.Separator {
    width: 1px;
    background: mat.get-color-from-palette($app-theme, 2100);
    margin: 50px auto;
    position: relative;
    height: 125px;

    &:before, &:after {
        content: ' ';
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        position: absolute;
        left: -3px;
        background: mat.get-color-from-palette($app-theme, 2100);
    }

    &:before {
        top: -3px;
    }

    &:after {
        bottom: -3px;
    }
}

.DNAStrand {
    width: 100%;
    margin: 35px 0;
}

.Message-text {
    font-size: 1rem;
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 1000);
    line-height: 1.6;
    margin-bottom: 18px;
}

.Message-step {
    text-align: center;
    margin: 25px 0 35px 0;
    padding: 0 30px;
}

.Message-stepImage {
    max-height: 100%;
}

.Message-stepTitle {
    font-size: 1.5rem;
    display: block;
    font-weight: 400;
    line-height: 1.33;
    text-align: left;
    padding: 40px 0 0 30px;
}

.Message-stepSubtitle {
    font-size: 0.95rem;
    display: block;
    font-weight: 500;
    line-height: 1.33;
    text-align: left;
    padding: 0 0 20px 30px;
}

.Message-stepText {
    font-size: 0.95rem;
    font-weight: 300;
    line-height: 1.6;
    text-align: left;
    padding: 0 0 0 30px;
    color: mat.get-color-from-palette($app-theme, 1000);
}

.Semibold {
    font-weight: 400 !important;
}

.ButtonBordered {
    padding: 7px 20px;
    border-radius: 18px;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 700;
    border: 2px solid;
    transition: color 0.3s ease, border-color 0.3s ease;

    color: mat.get-color-from-palette($app-theme, 2200) !important;
    border-color: mat.get-color-from-palette($app-theme, 2200) !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    transition: all 0.3s ease-out;

    &:visited {
        color: mat.get-color-from-palette($app-theme, 2200) !important;
        border-color: mat.get-color-from-palette($app-theme, 2200) !important;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
        transition: all 0.3s ease-out;
    }

    &:hover {
        text-decoration: none;
        background-color: transparent;
        color: mat.get-color-from-palette($app-theme, 700) !important;
        border-color: mat.get-color-from-palette($app-theme, 700) !important;
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
    }
}

.Button--countMeIn {
    margin: 0 auto !important;
    text-align: center;
    max-width: 180px;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 6px 15px 7px 15px;
    border-radius: 22px;
    display: block;
}

.Color--study {
    color: mat.get-color-from-palette($app-theme, 2200);
}

.Semibold {
    font-weight: 400 !important;
}

.PageContent-ol,
.PageContent-ul {
    margin: 20px !important;
    padding: 0 !important;
    text-align: left !important;
    font-weight: 300 !important;

    li {
        padding: 0 0 0 25px !important;
        font-size: 1.1rem !important;
        font-weight: 300 !important;
        margin: 35px 0 20px 0 !important;
        position: relative;

        &::before {
            position: absolute;
            display: inline-block;
            content: "";
            height: 100%;
        }

        ul {
            padding-left: 20px;
            margin: 20px 0;
        }

        > span:first-child {
            font-weight: 400;
        }
    }
}

.PageContent-ol {
    list-style-type: decimal;
    list-style-position: outside;
}

.NoListStyle {
    list-style-type: none;
}

.Login-landing-header {
    height: 78px;
    background-color: mat.get-color-from-palette($app-theme, 600);
}

.Modal-title {
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.5);
    margin: 10px 0 12px 0;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    position: relative;

    .mat-icon-button {
        top: -20px;
        right: -15px;
    }
}

.Modal-text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.95rem;
    line-height: 1.6rem;
    font-weight: 300;
    margin-bottom: 15px;
}

.ErrorMessage {
    border-top: 2px solid mat.get-color-from-palette($app-theme, 1200);
    margin-top: 5px;
    font-size: 0.9rem;
    color: mat.get-color-from-palette($app-theme, 1200);
    font-weight: 300;
    padding: 15px 0;

    a {
        text-decoration: underline !important;
    }
}

.ddp-activity-validation {
    margin: 5px 0;

    .ErrorMessageList {
        list-style-type: square;
    }

    .ErrorMessageItem {
        font-size: 0.9rem;
        color: mat.get-color-from-palette($app-theme, 1200);
        font-weight: 300;
        margin: 10px 0 10px 0;
        padding: 0 0 0 0;
    }
}

.JoinDialogFiled {
    width: 100%;
}

.JoinDialogNameField {
    width: 50%;
    margin: 0 30px 0 0;
}

.JoinDialogLastnameField {
    width: 50%;
}

.JoinDialogGroupFields {
    display: flex;
}

.JoinField--margin {
    margin-bottom: 5px;
}

@media only screen and (max-width: 650px) {
    .JoinDialogGroupFields {
        flex-direction: column;
    }

    .JoinDialogNameField {
        width: 100%;
        margin: 0;
    }

    .JoinDialogLastnameField {
        width: 100%;
    }
}

@media only screen and (max-width: 599px) {
    .HeaderInfo {
        margin: 0
    }
}

.ResendEmailField {
    width: 100%;
    margin-bottom: 5px;
}

.ConsentButtons {
    display: flex;
    justify-content: space-between;
}

.NextButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.WizardSteps {
    position: relative;
    margin: 40px 0 30px 0;
    text-align: center;
    cursor: pointer;

    &.completed {
        .WizardSteps-background {
            background: mat.get-color-from-palette($app-theme, 2300);
        }

        &.active {
            .WizardSteps-background {
                background: mat.get-color-from-palette($app-theme, 600);
            }

            .WizardSteps-title {
                color: mat.get-color-from-palette($app-theme, 100);
            }
        }
    }
}

.WizardSteps-background {
    background: mat.get-color-from-palette($app-theme, 100);
    margin-top: 40px;
    border-radius: 12px;
    z-index: 0;
    width: 100%;
}

.WizardSteps-title {
    padding: 60px 0 15px 0;
    font-size: 1.1rem;
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 800);
}

.WizardSteps-img {
    position: absolute;
    top: 0;
    left: calc((100% - 70px) / 2);
    width: 70px;
    height: 85px;
    background-color: mat.get-color-from-palette($app-theme, 100);
    box-shadow: 0 0 5px mat.get-color-from-palette($app-theme, 800);
}

.Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 12px solid mat.get-color-from-palette($app-theme, 600);
    border-top: 12px solid mat.get-color-from-palette($app-theme, 2300);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-top: -50px;
    margin-left: -30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Sidenav li {
    text-align: start;
    padding: 0 !important;
}

.Loader-height {
    height: 50vh;
}

.PageContent-box {
    width: 100%;
    padding: 3rem;
    background: mat.get-color-from-palette($app-theme, 200);
    border: 2px solid mat.get-color-from-palette($app-theme, 600);
    box-shadow: none;
    font-size: 1rem;
}

.PageContent-box-text {
    color: mat.get-color-from-palette($app-theme, 1000);
}

.PageContent-strong-text {
    margin: 0 0 0 40px;
    font-weight: 400;
}

@media only screen and (max-width: 450px) {
    .PageContent-box {
        padding: 2.2rem 2rem 2rem 2rem;
    }
}

.PageContent-contact {
  list-style-type: disc;
  margin: 10px 0 0 0;
  padding: 0;
}

.PageContent-contact-item {
    font-weight: 300 !important;
    font-size: 1.1rem !important;
    line-height: 1.6rem;
    margin: 0 !important;
}

.PageContent-list-item {
    list-style-type: circle !important;

    &.PageContent-text {
        color: mat.get-color-from-palette($app-theme, 1000);
        padding-left: 0 !important;
        margin: 15px 0 5px 0 !important;
    }
}

.PageContent-info {
    margin: 0 20px;
}

.PageContent-closing-text {
    color: mat.get-color-from-palette($app-theme, 800);
    font-size: 1rem;
    font-weight: 300;
    margin: 0 20px;
}

.PageContent-closing-question {
    margin: 35px 20px -25px 20px !important;
}

.PageContent-infobox-section {
    padding: 2rem 0 0 0;
}

.PageContent-infobox {
    padding: 1rem;
    background: mat.get-color-from-palette($app-theme, 200);
    border: 2px solid mat.get-color-from-palette($app-theme, 600);
    box-shadow: none;
    font-size: 1rem;
    margin: 0 20px;
}

.PageContent-subtitle {
    color: mat.get-color-from-palette($app-theme, 1000);
    font-weight: 400 !important;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 2rem 0 1rem 0;

    &.council {
        margin-top: 0;
        font-size: 1.4rem;
        line-height: 1.4rem;
    }
}

.PageContent-subtitle-dashboard {
    margin: 0.5rem 0 !important;
}

.PageContent-text-dashboard {
    font-size: 1rem !important;
}

.reverse-row {
    display: block;
}

.council {
    @media only screen and (min-width: 768px) {
        .col-xs-offset-2 {
            margin-left: auto;
        }

        .reverse-row {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    @media only screen and (max-width: 768px) {
        .PageContent-image {
            margin-bottom: 20px;
        }

        .PageContent-text {
            margin: 0;
        }
    }
}

.council-row {
    margin: 25px 0 150px 0;
}

.PageContent-text br {
    line-height: 2.5rem;
}

.Button--cell {
    font-size: 0.85rem;
    min-width: 90px !important;
    height: 30px;
    line-height: 26px !important;
    padding: 0 !important;
    border-radius: 2px !important;
    outline: none;
}

.Announcements-section {
    align-self: stretch;
}

select {
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid mat.get-color-from-palette($app-theme, 1800);
    border-radius: 2px;
    height: 3rem;
    transition: all .3s;

    &:focus {
        color: mat.get-color-from-palette($app-theme, 600);
        outline: none;
    }

    option {
        color: black;
        font-weight: 300;
    }
}

select:-webkit-autofill {
    background-color: transparent !important;
}

.date-field,
ddp-activity-dropdown-picklist-question {
    > select {
        border: none;
        border-bottom: 1px solid mat.get-color-from-palette($app-theme, 1900);

        &:focus {
            border-bottom: 2px solid mat.get-color-from-palette($app-theme, 600);
            padding-bottom: 4px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .Button--cell {
        min-width: 95% !important;
    }
}

.warning-message {
    position: fixed;
    height: $warning-height;
    top: 0;
    left: 0;
    width: 100%;
    background-color: mat.get-color-from-palette($app-theme, 2000);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;

    .warning-message-text {
        color: mat.get-color-from-palette($app-theme, 1700);
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: 300;
        width: 100%;
        text-align: center;
    }
}

.modal-message {
    .warning-message-text {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.95rem;
        line-height: 1.6rem;
        font-weight: 300;
        margin-bottom: 15px;
    }
}

.warning-message-top {
    top: $warning-height !important;
}

.CenterDivTableau {
    text-align: center;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1060px;
}

@media only screen and (min-width: 1440px) {
    .CenterDivTableau {
        max-width: 1200px;
    }
}

.youtube-video {
    align-self: center;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.ddp-list {
    margin: 20px;
    padding: 0;
}

.ddp-list li {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 35px 0 20px 0;
    color: mat.get-color-from-palette($app-theme, 1700);
    padding: 0 0 0 25px;
}

.ddp-list-inside {
    margin: 0;
    padding: 0;
    list-style-position: inside;
}

.ddp-list-inside li {
    padding: 0;
    font-size: 1.2rem;
    font-weight: 400;
}

.ddp-agreement-text {
    white-space: normal;
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 800);
}

.ddp-question {
    margin: 35px 0 20px 0;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: mat.get-color-from-palette($app-theme, 1700) !important;
    padding: 0;

    .ddp-required-question-prompt {
        color: mat.get-color-from-palette($app-theme, 800);
        font-size: 1.1rem;
    }

    li {
        color: mat.get-color-from-palette($app-theme, 800);
    }
}

.ddp-question-neutral {
    color: mat.get-color-from-palette($app-theme, 800) !important;
}

.ddp-required-question-prompt {
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 1700) !important;
}

.ddp-list {
    p {
        font-size: 1.1rem;
        color: mat.get-color-from-palette($app-theme, 1700);

        &.ddp-question-prompt {
            font-weight: 400;
        }
    }
}

.ddp-dropdown {
    padding: 10px;
    margin: 0 0 5px 0;
    border: 1px solid mat.get-color-from-palette($app-theme, 1800);
    border-radius: 2px;
}

.ddp-block-body {
    margin: 20px 0 0 0;

    .PageContent-text {
        margin: 14px 0 0 0;
    }
}

.ddp-block-title-bold {
    color: mat.get-color-from-palette($app-theme, 1000);
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 2rem 0 1rem 0;
}

.ddp-single-question {
    margin: 35px 20px 20px 45px;
}

.ddp-block-title-color {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 400;
    margin: 2rem 0 1rem 0;
    color: mat.get-color-from-palette($app-theme, 800);
}

.ddp-li-bullet {
    color: mat.get-color-from-palette($app-theme, 1000) !important;
}

.ddp-date-field-margin {
    margin: 0 15px 0 0;
}

.ddp-content {
    width: 100%;
}

.ddp-agreement {
    margin: 0 0 0 -25px;
}

.ddp-group-block {
    margin: 0;
}

.ddp-group-block-list {
    padding: 0 !important;
}

.ddp-textarea-block {
    .mat-form-field-infix {
        border-top: 0 solid transparent !important;
    }
}

.mat-form-field-infix {
    > select {
        border-bottom: none !important;
    }
}

.ddp-group-margin {
    margin: 20px;
}

.ddp-textarea {
    height: 1.5rem !important;
}

.ddp-composite-text {
    flex-grow: 1;
    margin-right: 30px;
}

.ddp-close-button {
    margin-left: auto;
    cursor: pointer;
    color: mat.get-color-from-palette($app-theme, 300);
    font-size: 1rem;
    font-weight: 900;
}

.ddp-user-menu-button {
    outline: none;
    font-size: 1rem;
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 1000);

    &:active, &:focus, &:hover {
      color: mat.get-color-from-palette($app-theme, 1000);
    }
}

.ddp-user {
    color: mat.get-color-from-palette($app-theme, 100);

    &--scrolled {
        color: mat.get-color-from-palette($app-theme, 2200);
    }
}

.ddp-institutions-form,
.ddp-activity-question {
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.ddp-address-embedded,
.ddp-institutions-form {
    &__title, &__subtitle {
        font-weight: 400;
    }
}

.ddp-hide-block {
    margin: 0 !important;
    padding: 0 !important;
    height: 0;
}

.ddp-hide-subtitle {
    opacity: 0;
    transition: all 0.3s ease;
}

.ddp-menu-text {
    padding: 0 16px !important;
}

.ddp-dashboard {
    .dashboard-mobile-label {
        color: rgba(0, 0, 0, 0.54);
    }
}

.dashboard-activity-button {
    font-size: 0.9rem;
    font-family: inherit;
    font-weight: 400;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
}

.ddp-picklist-option-list-item {
    height: auto !important;
    margin-bottom: 10px;
}

.ddp-picklist-group-title {
    font-size: 1.25rem;
    font-weight: 500;
}

.example-radio-group {
    display: flex;
    flex-direction: row;
}

.ddp-login-button {
    @extend .mat-button;
}

.subhead:after {
    content: "";
    display: table;
    clear: both;
}

/**
    Define the prompt to be displayed after a required question
*/
.ddp-required-question-prompt:after {
    content: ' *';
}

@media only screen and (max-width: 1100px) {
    .ddp-picklist-group-container {
        grid-template-columns: repeat(2, 50%) !important;
    }
}

@media only screen and (max-width: 600px) {
    .ddp-picklist-group-container {
        grid-template-columns: repeat(1, 100%) !important;
    }
}

.mat-snack-bar-container {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
    margin: 4px;
    background-color: mat.get-color-from-palette($app-theme, 100);
    padding: 0 !important;
}

.mat-checkbox-label {
    color: mat.get-color-from-palette($app-theme, 1700);
    font-weight: 300;
}

.mat-select-placeholder {
    color: mat.get-color-from-palette($app-theme, 1700);
}

.mat-radio-label {
    font-weight: 300;
}

.mat-checkbox-layout {
    align-items: normal !important;

    .mat-checkbox-inner-container {
        margin-top: 2px;
    }
}

.mat-input-flex {
    font-weight: 300;
}

.mat-dialog-container {
    background-color: mat.get-color-from-palette($app-theme, 1300);
}

.mat-form-field-invalid .mat-form-field-label {
    color: mat.get-color-from-palette($app-theme, 1200);
}

.mat-error {
    color: mat.get-color-from-palette($app-theme, 1200);
}

.mat-form-field-invalid .mat-form-field-ripple {
    background-color: mat.get-color-from-palette($app-theme, 1200);
}

.mat-form-field-label-wrapper {
    font-weight: 300;
    font-size: 1rem;
}

.mat-input-placeholder {
    font-weight: 300;
    font-size: 1rem;
}

.mat-error {
    font-weight: 300;
    font-size: 0.75rem;
}

.mat-checkbox-frame {
    border-color: mat.get-color-from-palette($app-theme, 900);
}

.mat-radio-outer-circle {
    border-color: mat.get-color-from-palette($app-theme, 900);
}

.mat-list-item-content {
    padding: 0 4px 0 0 !important;
}

.mat-checkbox-layout .mat-checkbox-label {
    font-size: 1rem;
}

.mat-radio-label-content {
    font-size: 1rem;
}

.mat-form-field-infix {
    font-size: 1rem;
}

.mat-list .mat-list-item .mat-list-item-content {
    display: block !important;
}

.mat-input-element {
    height: 2.0rem !important;
    font-size: 1rem;
}

input.mat-input-element {
    height: 1.2rem !important;
    font-size: 1rem;
}

.mat-form-field .mat-form-field-subscript-wrapper {
    overflow: visible !important;
}

.mat-input-element {
    line-height: 1.1rem;
    font-size: 1rem;
}

.mat-radio-outer-circle,
.mat-radio-inner-circle {
    height: 16px !important;
    width: 16px !important;
}

.mat-radio-container {
    margin-top: 3px !important;
    height: 16px !important;
    width: 16px !important;
}

.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(.8) !important;
}

.mat-menu-item {
    padding: 0 !important;
}

@media only screen and (min-width: 768px) {

    .mat-row:first-child,
    .mat-header-row:first-child {
        flex: 0 0 40% !important;
    }

    .mat-row:last-child,
    .mat-header-row:last-child {
        flex: 0 0 15% !important;
    }
}

.mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple {
    opacity: 0 !important;
}

.mat-checkbox-inner-container {
    width: 20px !important;
    height: 20px !important;
}

.mat-input-element {
    font-weight: 100 !important;
}

.mat-select {
    font-weight: 100 !important;
}

.ddp-helper {
    font-size: 0.8rem !important;
    color: mat.get-color-from-palette($app-theme, 800) !important;
}

.ddp-counter-color {
    color: mat.get-color-from-palette($app-theme, 2200) !important;
}

.ddp-answer-container {
    display: flex;
    flex-direction: row;
}

.mat-option-text {
    line-height: 16px !important;
    white-space: normal !important;
}

@media only screen and (max-width: 480px) {
    .mat-option-text {
        font-size: 14px !important;
    }

    .mat-form-field-placeholder {
        font-size: 12px !important;
        white-space: normal !important;
    }

    .composite-answer-OTHER_CANCERS_LIST {
        .ddp-answer-container {
            flex-direction: column !important;
        }

        .ddp-composite-text {
            margin-right: 0;
        }

        .mat-form-field {
            width: 100%;
        }
    }

    .date-answer-DIAGNOSIS_DATE {
        .ddp-answer-container {
            flex-direction: column !important;
        }

        select:last-child {
            margin-top: 10px;
        }
    }
}

.mat-drawer-content {
    overflow: hidden !important;
}
